<template>
  <div>
    <!-- 위험등록부 목록 -->
    <c-table
      ref="tableRisk"
      title="LBL0001203"
      tableId="riskRegister01"
      :columns="gridRisk.columns"
      :data="riskReduce.riskbooks"
      :merge="gridRisk.merge"
      :gridHeight="setheight"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :requestContentsCols="requestContentsCols"
            tableKey="ramChecklistAssessScenarioId"
            ibmTaskTypeCd="ITT0000010"
            ibmTaskUnderTypeCd="ITTU000010"
            @imprChange="imprChange"
          />
        </template>
        <template v-else-if="col.name==='picture'">
          <q-btn 
            unelevated round dense
            size="7px"
            color="primary" 
            icon="wallpaper"
            @click.stop="openImprPicture(props)" />
        </template>
        <template v-else-if="col.name==='checkItemName'">
          <template v-if="props.row.checkItemId && props.row.ramCheckItemClassCd !== 'RCIC000015'">
            <!-- 작업, 기계·기구, 유해인자 & link 걸린 대상 -->
            <q-btn
              flat
              class="tableinnerBtn"
              align="left"
              color="blue-6"
              :label="props.row[col.name]"
              @click.stop="openItem(props.row)" />
          </template>
          <template v-else>
            <!-- 기타 & link 걸리지 않은 대상 -->
            {{props.row[col.name]}}
          </template>
        </template>
      </template>
    </c-table>
    <!-- 개선 목록 -->
    <c-table    
      ref="tableImpr"
      title="LBL0001174"
      tableId="impr01"
      :columns="gridImpr.columns"
      :data="riskReduce.imprs"
      :merge="gridImpr.merge"
      :gridHeight="setheight"
      @linkClick="linkClick"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='picture'">
          <q-btn 
            unelevated round dense
            size="7px"
            color="primary" 
            icon="wallpaper"
            @click.stop="openImprPicture(props)" />
        </template>
        <template v-else-if="col.name==='checkItemName'">
          <template v-if="props.row.checkItemId && props.row.ramCheckItemClassCd !== 'RCIC000015'">
            <!-- 작업, 기계·기구, 유해인자 & link 걸린 대상 -->
            <q-btn
              flat
              class="tableinnerBtn"
              align="left"
              color="blue-6"
              :label="props.row[col.name]"
              @click.stop="openItem(props.row)" />
          </template>
          <template v-else>
            <!-- 기타 & link 걸리지 않은 대상 -->
            {{props.row[col.name]}}
          </template>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'checklist-risk-register-TL',
  props: {
    param: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramStepCd: '',
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      riskReduce: {
        imprs: [],
        riskbooks: [],
      },
      gridRisk: {
        merge: [
          { index: 0, colName: 'processCd' },
          { index: 1, colName: 'checkClassGroup' },
          { index: 2, colName: 'checkItemGroup' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'ramCheckItemClassName',
            field: 'ramCheckItemClassName',
            // 분류
            label: 'LBLCLASSIFICATION',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 평가대상
            label: 'LBL0001028',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'checklistQuestion',
            field: 'checklistQuestion',
            // 체크리스트 문항
            label: 'LBL0001092',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ramInspectResultName',
            field: 'ramInspectResultName',
            // 결과
            label: 'LBL0001007',
            align: 'center',
            style: 'width:90px',
          },
          {
            name: 'picture',
            field: 'picture',
            // 개선<br/>전/후<br/>사진
            label: 'LBL0001176',
            align: 'center',
            type: 'custom',
            style: 'width:50px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 개선대책
            label: '개선대책',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:130px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        data: [],
        height: '500px'
      },
      gridImpr: {
        merge: [
          { index: 0, colName: 'processCd' },
          { index: 1, colName: 'checkClassGroup' },
          { index: 2, colName: 'checkItemGroup' },
          { index: 3, colName: 'ramChecklistAssessScenarioId' },
          { index: 4, colName: 'ramChecklistAssessScenarioId' },
          { index: 5, colName: 'ramChecklistAssessScenarioId' },
          { index: 6, colName: 'ramChecklistAssessScenarioId' },
          { index: 7, colName: 'ramChecklistAssessScenarioId' },
          { index: 8, colName: 'ramChecklistAssessScenarioId' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'ramCheckItemClassName',
            field: 'ramCheckItemClassName',
            // 분류
            label: 'LBLCLASSIFICATION',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 평가대상
            label: 'LBL0001028',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'checklistQuestion',
            field: 'checklistQuestion',
            // 체크리스트 문항
            label: 'LBL0001092',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ramInspectResultName',
            field: 'ramInspectResultName',
            // 결과
            label: 'LBL0001007',
            align: 'center',
            style: 'width:90px',
          },
          {
            name: 'picture',
            field: 'picture',
            // 개선<br/>전/후<br/>사진
            label: 'LBL0001176',
            align: 'center',
            type: 'custom',
            style: 'width:50px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 개선대책
            label: '개선대책',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            // 요청부서정보
            label: '요청부서정보',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료요청일
            label: 'LBL0001017',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0001018',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        height: '500px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      imprData: null,
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 20) + 'px' : '500px'
    },
    requestContentsCols() { 
      return ['ramCheckItemClassName', 'checkItemName'];
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.checklist.riskReduce.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.$_.extend(this.riskReduce, _result.data);
      },);
    },
    /* eslint-disable no-unused-vars */
    imprChange() {
      this.$refs['tableRisk'].$refs['compo-table'].resetVirtualScroll();
    },
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    openItem(row) {
      if (row.ramCheckItemClassCd === 'RCIC000001') {
        this.popupOptions.title = 'LBL0001093'; // 안전작업표준 상세
        this.popupOptions.param = {
          mdmSopId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/pim/sopDetail.vue'}`);
      } else if (row.ramCheckItemClassCd === 'RCIC000005') {
        this.popupOptions.title = "LBL0000956"; // 유해위험기계기구 상세
        this.popupOptions.param = {
          hhmHazardousMachineryId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
      } else {
        this.popupOptions.title = 'LBL0001094'; // 유해인자 상세
        this.popupOptions.param = {
          hazardCd: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sai/wmr/harmfulFactorsDetail.vue"}`);
      }
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = 'LBL0001181'; // 개선 사진
      this.popupOptions.param = {
        ramChecklistAssessScenarioId: props.row.ramChecklistAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/checklist/action/checklistScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>